.Socials {
    display: inherit;
    background-color: #1e3d72;
    align-content: center;
}

.social-icon {
    width: 60px;
    height: 60px;
    align-items: justify;
    padding: 10px;

}

.hover {
    color: white;
    size: 10px;
}